@use 'sass:map';
@use 'shared/variables';

/**
* DO NOT IMPORT THIS FILE IN OTHER COMPONENTS
* Importing this file in other components will override custom branding styles
* Reference issue: https://github.com/trade-platform/trade-platform/issues/6113
**/

/** DO NOT INCLUDE IN THE S3 SCSS TEMPLATE  **/
/** IMPORTANT - in s3 template, $branding needs to be 'whitelabel' **/
$branding: 'aix';
/** INCLUDE BELOW IN THE S3 SCSS TEMPLATE  **/

@if $branding == 'aix' {
    $brand-colors: (
        primary: variables.$aix-color-primary,
        primary--dark: variables.$aix-color-primary--dark,
        primary--light: variables.$aix-color-primary--light,
        secondary: variables.$aix-color-secondary,
        secondary--dark: variables.$aix-color-secondary--dark,
        secondary--light: variables.$aix-color-secondary--light,
        navbar--bg: variables.$aix-color-navbarbg,
        navbar--text: variables.$aix-color-navbartext,
        navbar--hover: variables.$aix-color-navbarhover
    );
}

@if $branding == 'whitelabel' {
    $brand-colors: (
        // NB> The usage of "color-primary" and "color-secondary" is reversed for whitelabel branding.
        // Whitelabel branding uses $color-secondary for its button and link colors, while AIX uses $color-primary.
        // Admittedly confusing here in the code, where we see $brand-colors['primary'] return $color-secondary.
        // These colors will be applied to the backgrounds of the header and footer.
        primary:
            variables.$color-secondary,
        primary--dark: variables.$color-secondary--dark,
        primary--light: variables.$color-secondary--light,
        // These colors will be applied to buttons, links, and other actionable areas.
        secondary:
            variables.$color-primary,
        secondary--dark: variables.$color-primary--dark,
        secondary--light: variables.$color-primary--light,
        // These colors will be applied to the navbar
        navbar--bg:
            variables.$color-navbarbg,
        navbar--text: variables.$color-navbartext,
        navbar--hover: variables.$color-navbarhover
    );
}

/* ====================

   When this file is updated, the code below this comment block should be copied into the
   trade-platform/lib-sass-templates repository under static/_brand.scss (aka the S3 SCSS TEMPLATE).
   That file is what is generated and returned server-side when specific colors are set for a tenant.

  ==================== */

$css-properties: (
    background-color,
    border-bottom-color,
    border-color,
    border-left-color,
    border-right-color,
    border-top-color,
    color,
    fill,
    outline-color,
    stroke
);

$css-pseudo-classes: (hover, focus, active, before, after);

// future-proofing, allows e.g. box-shadow: 0 2px 2px var(--box-shadow-color);
$css-custom-properties: (box-shadow-color, text-shadow-color);

// generate generic color classes
// -----------------------------------------------------------------------------

@each $name, $color in variables.$brand-colors {
    @each $prop in $css-properties {
        .br-#{$name}__#{$prop} {
            #{$prop}: $color;
        }

        @each $cls in $css-pseudo-classes {
            .br-#{$name}__#{$prop}--#{$cls}:#{$cls} {
                #{$prop}: $color;
            }
        }
    }

    @each $prop in $css-custom-properties {
        .br-#{$name}__#{$prop} {
            --#{$prop}: #{$color};
        }
    }
}

// generate semantic classes for some common elements and patterns
// -----------------------------------------------------------------------------

.br-btn {
    background-color: map.get(variables.$brand-colors, 'primary');
    border-color: map.get(variables.$brand-colors, 'primary');
    color: variables.$color-white;

    &--link {
        color: map.get(variables.$brand-colors, 'primary');
        background-color: variables.$color-white;
        border-color: variables.$color-white;
    }

    &--primary {
        background-color: map.get(variables.$brand-colors, 'primary');
        border-color: map.get(variables.$brand-colors, 'primary');
        color: variables.$color-white;
    }

    &--no-bg, // NB> no-bg modifier should be removed
    &--secondary {
        border-color: map.get(variables.$brand-colors, 'primary');
        color: map.get(variables.$brand-colors, 'primary');
    }

    &:hover,
    &:focus {
        background-color: map.get(variables.$brand-colors, 'primary--dark');
        border-color: map.get(variables.$brand-colors, 'primary--dark');
    }

    &:active {
        background-color: map.get(variables.$brand-colors, 'primary--dark');
        border-color: map.get(variables.$brand-colors, 'primary--dark');
    }
}

.br-link {
    color: map.get(variables.$brand-colors, 'primary');
    outline: none;

    &:hover,
    &:focus {
        color: map.get(variables.$brand-colors, 'primary--light');
    }

    &.is-selected,
    &:active {
        color: map.get(variables.$brand-colors, 'primary--dark');
    }

    // NB> When do we use this? - LMG 2019-02-13
    &-black {
        color: variables.$color-primary-black;

        &.is-selected {
            color: map.get(variables.$brand-colors, 'primary');
        }

        &:hover,
        &:focus {
            color: map.get(variables.$brand-colors, 'primary');
        }

        &:active {
            color: map.get(variables.$brand-colors, 'primary--dark');
        }
    }

    &-grey {
        color: variables.$color-tertiary-black;

        &.is-selected {
            color: map.get(variables.$brand-colors, 'primary');
        }

        &:hover,
        &:focus {
            color: map.get(variables.$brand-colors, 'primary');
        }

        &:active {
            color: map.get(variables.$brand-colors, 'primary--dark');
        }
    }

    // This is the inverse link displayed on dark backgrounds
    &-white {
        color: variables.$color-white;

        &.is-selected {
            color: map.get(variables.$brand-colors, 'primary');
        }

        &:hover,
        &:focus {
            color: map.get(variables.$brand-colors, 'primary');
        }

        &:active {
            color: map.get(variables.$brand-colors, 'primary--dark');
        }
    }
}

.aix-grid.-auxiliary .ag-row.ag-row-selected,
.aix-grid.-auxiliary .ag-body .ag-row-selected,
.aix-grid.-auxiliary .ag-row-even.ag-row-selected {
    &:not(.no-hover) {
        @if $branding == 'aix' {
            background-color: variables.$aix-color-secondary--light;
        }
        @if $branding == 'whitelabel' {
            background-color: map.get(variables.$brand-colors, 'primary--light');
        }
    }
}

.ag-body .ag-row-hover,
.aix-grid .ag-row.ag-row-hover,
.aix-grid .ag-body .ag-row-hover,
.aix-grid.-auxiliary .ag-row-even.ag-row-hover {
    &:not(.no-hover) {
        @if $branding == 'aix' {
            background-color: variables.$aix-color-secondary--light;
        }
        @if $branding == 'whitelabel' {
            background-color: map.get(variables.$brand-colors, 'primary--light');
        }
    }
}

.aix-grid.ag-theme-balham .ag-row.ag-row-hover:not(.no-hover),
.aix-grid.ag-theme-balham .ag-row-hover:not(.ag-full-width-row)::before {
    @if $branding == 'aix' {
        background-color: variables.$aix-color-secondary--light;
    }
    @if $branding == 'whitelabel' {
        background-color: map.get(variables.$brand-colors, 'secondary--light');
    }
}

.aix-pagination__button {
    &:hover,
    &.is-current {
        @if $branding == 'aix' {
            background-color: variables.$aix-color-secondary--light;
        }
        @if $branding == 'whitelabel' {
            // NB> Good place to try a color contrast value for the text
            background-color: map.get(variables.$brand-colors, 'primary--light');
        }
    }
}

/**
* Navigation and footer background styles
**/

.br-navigation--primary__background-color,
.aix-footer {
    @if $branding == 'aix' {
        background-color: variables.$color-primary-black;
    }
    @if $branding == 'whitelabel' {
        background-color: map.get(variables.$brand-colors, 'secondary');
    }
}

// Top header and footer color
.br-navigation--secondary__background-color {
    @if $branding == 'aix' {
        background-color: variables.$color-secondary-black;
    }
    @if $branding == 'whitelabel' {
        background-color: map.get(variables.$brand-colors, 'secondary--dark');
    }
}

// Main navigation bar
.br-navbar {
    @if $branding == 'aix' {
        background-color: variables.$aix-color-navbarbg;
    }
    @if $branding == 'whitelabel' {
        background-color: map.get(variables.$brand-colors, 'navbar--bg');
    }
}

.br-navbar--link {
    @if $branding == 'aix' {
        color: variables.$aix-color-navbartext;
    }
    @if $branding == 'whitelabel' {
        color: map.get(variables.$brand-colors, 'navbar--text');
    }

    &.is-selected,
    &:hover {
        @if $branding == 'aix' {
            color: variables.$aix-color-navbarhover;
        }
        @if $branding == 'whitelabel' {
            color: map.get(variables.$brand-colors, 'navbar--hover');
        }
    }
}

/**
* Forms components branding styles
**/

aix-dynamic-free-text a {
    @extend .br-link;
}

.br-aix-notification,
.aix-notification-brand {
    @if $branding == 'whitelabel' {
        border-left-color: map.get(variables.$brand-colors, 'primary');
        background-color: map.get(variables.$brand-colors, 'secondary--light');
    }
}

.aix-pill.brand {
    @if $branding == 'aix' {
        border-color: variables.$aix-color-secondary;
        background-color: variables.$aix-color-secondary--light;
    }
    @if $branding == 'whitelabel' {
        border-color: map.get(variables.$brand-colors, 'primary');
        background-color: map.get(variables.$brand-colors, 'primary--light');
    }
}
