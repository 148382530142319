@media print,
       (min-resolution: 1.25dppx),
       (min-resolution: 120dpi) {
}

@media print {
    /**
     * A basic print reset
     */
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important; /* Black prints faster */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    header.header--app {
        margin-top: 0;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    /**
     * Individual components to hide when printing
     */
    aix-print-button {
        display: none;
    }

    .show-if-print {
        display: block !important;
    }
    .hide-if-print {
        display: none !important;
    }
    .page-break-on-print {
        page-break-after: avoid;
    }
    .page-break {
        page-break-after: always;
        clear: both;
    }

    /**
     * Preserve background color when printing. -webkit only
     */
    .print-background {
        -webkit-print-color-adjust: exact !important;
    }
}
