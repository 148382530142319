@use "../shared/mixins";
@use "../shared/variables";

// How to use: .flex-(horizontal align)-(vertical align)
.aix-card {

    background-color: variables.$color-white;
    border: 1px solid variables.$color-quaternary-black;
    padding: 24px;
    margin-bottom: 30px;

    // IE11 Bug: https://stackoverflow.com/questions/43024415/how-to-fix-flex-column-height-bug-in-ie
    min-height: 1px;

    &--flex {
        display: flex;
        flex-direction: column;
    }

    &-header {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
    }

    &-center {
        text-align: center;
    }

    &-sticky {
        position: sticky;
        top: 0;
        z-index: mixins.z('sticky');
        margin-bottom: -1px;
        background-color: variables.$color-white;
    }


    &-links {
        list-style: none;
        margin-top: 5px;
        padding: 0;

        &-link {
            @include mixins.fz(1.3);
            @include mixins.lh(2.2);

            text-decoration-style: dotted;
            margin-right: 10px;

            &--solid {
                text-decoration-style: solid;
            }

            &:hover {
                color: variables.$color-primary;
                text-decoration: underline;
            }
        }
    }

    &__dropshadow {
        @include mixins.drop-shadow;
    }
}
