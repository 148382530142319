// Flex helper classes and mixins
@mixin flex-left-stretch {
    display: flex;
}
.flex-left-stretch {
    @include flex-left-stretch;
}

@mixin flex-col {
    flex-direction: column;
}
.flex-col {
    @include flex-col;
}

@mixin flex-row {
    flex-direction: row;
}
.flex-row {
    @include flex-row;
}


@mixin flex-left-top {
    display: flex;
    align-items: flex-start;
}
.flex-left-top {
    @include flex-left-top;
}

@mixin flex-left-center {
    display: flex;
    align-items: center;
}
.flex-left-center {
    @include flex-left-center;
}

@mixin flex-left-bottom {
    display: flex;
    align-items: flex-end;
}
.flex-left-bottom {
    @include flex-left-bottom;
}

@mixin flex-center-top {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.flex-center-top {
    @include flex-center-top;
}

@mixin flex-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-center-center {
    @include flex-center-center;
}

@mixin flex-center-bottom {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.flex-center-bottom {
    @include flex-center-bottom;
}

@mixin flex-between-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.flex-between-top {
    @include flex-between-top;
}

@mixin flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex-between-center {
    @include flex-between-center;
}

@mixin flex-right-top {
    display: flex;
    justify-content: flex-end;
}
.flex-right-top {
    @include flex-right-top;
}

@mixin flex-right-bottom {
    display: flex;
    justify-content: flex-end;

}
.flex-right-bottom {
    @include flex-right-bottom;
}

@mixin flex-right-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flex-right-center {
    @include flex-right-center;
}

@mixin flex-wrap {
    flex-wrap: wrap;
}
.flex-wrap {
    @include flex-wrap;
}

@mixin flex-fill {
    flex: 1 1 auto;
}
.flex-fill {
    @include flex-fill;
}

@mixin flex-spacer {
    flex-grow: 1;
}
.flex-spacer {
    @include flex-spacer;
}
