@use 'shared/variables';
@use 'shared/mixins';

.aix-sticky {

    &__spacer {
        display: none;
    }

    &--stuck {
        position: fixed;
        z-index: mixins.z('sticky');

        &.aix-sticky--top {
            top: 0;
        }

        &.aix-sticky--bottom {
            bottom: 0;
        }

        + .aix-sticky__spacer {
            display: block;
        }
    }

    &--native {
        padding-bottom: 0px;
        position: sticky;
        z-index: mixins.z('sticky');

        &.aix-sticky--top {
            top: 0;
        }

        &.aix-sticky--bottom {
            bottom: 0;
        }
    }
}
