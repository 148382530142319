@use 'libs/ui-components/src/lib/scss/shared/_variables.scss';
@use 'libs/ui-components/src/lib/scss/shared/_mixins.scss';

// /* Loading spinner */
.loading-tiny {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.host-flex {
    flex: 1 0 auto;
}

.files-selected {
    padding: 24px;
    border: 1px dashed variables.$color-quaternary-black;
    margin-top: -1px;
}

.file-row {
    display: flex;
    justify-content: space-between;
    height: 24px;

    @include mixins.fz(1.3);
    @include mixins.lh(1.6);

    &__name {
        max-width: 330px;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;

        &--text {
            @include mixins.ellipsis;
        }
    }

    &__warning-message {
        justify-content: flex-end;
    }

    &__actions {
        width: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &--disabled {
            min-width: 140px;
            color: variables.$color-tertiary-black;
        }

        &--close {
            @include mixins.fz(1.1);
            color: variables.$color-tertiary-black;
            cursor: pointer;
        }
    }

    &__disabled {
        max-width: 200px;
        color: variables.$color-tertiary-black;
    }
}
