@use "../shared/mixins";
@use "../shared/variables";

.sidebar--left {
    border-right: 1px solid variables.$color-quaternary-black;
    flex-basis: variables.$width-sidebar;
    min-width: variables.$width-sidebar;
    max-width: variables.$width-sidebar;
    position: sticky;
    top: 0;
    z-index: mixins.z('sticky');
    padding: 24px 12px 24px 0;

    h4 {
        font-size: 16px;
        line-height: 24px;
    }

    section {
        padding: 0px;
        margin-top: 0px;
    }
}
