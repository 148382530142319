@use "libs/ui-components/src/lib/scss/shared/mixins";
@use "libs/ui-components/src/lib/scss/shared/variables";

/**
 * Add here any changes to aix-data-grid
 */

.document-cell-renderer {
    @include mixins.fz(1.2);
    font-weight: 400;
    color: variables.$color-tertiary-black;
}
