@use "../shared/mixins";
@use "../shared/variables";

/* ==========================================================================
   Body text
   ========================================================================== */

p,
.-text--normal {
    @include mixins.body-style-normal;
}

.-text--strong {
    @include mixins.font-weight-medium;
}

small,
.-text--small {
    @include mixins.body-style-small;
}

.-text--large {
    @include mixins.body-style-large;
}

.-text--info {
    @include mixins.body-style-small;
    color: variables.$color-tertiary-black;
}

.-text--label {
    @include mixins.text-style-label;
}

/* ==========================================================================
   Headers and headings
   ========================================================================== */

h1 {
    @include mixins.h1-style;
}

h2 {
    @include mixins.h2-style;
}

h3 {
    @include mixins.h3-style;
}

h4 {
    @include mixins.h4-style;
}

h5 {
    @include mixins.h5-style;
}

h6 {
    @include mixins.h6-style;
    color: variables.$color-tertiary-black;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    margin: 0 0 8px 0;
    padding: 0;
}
