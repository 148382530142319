@use "../flex-helpers";
@use "../shared/variables";

/**
 * Toolbars
 */
.aix-toolbar {
    @include flex-helpers.flex-right-center;

    .aix-btn {
        margin-right: 12px;
    }

    aix-loading-button:last-of-type,
    aix-button:last-of-type,
    button:last-of-type {
        margin-right: 0;
    }

    aix-button + aix-loading-button {
        margin-right: 12px;
    }

    &.aix-toolbar-bottom-separator {
        border-bottom: 2px dotted variables.$color-quaternary-black;
    }

    aix-loading-button:last-of-type:has(+ aix-button) {
        margin-right: 12px;
    }
}

/**
 * The distance between confirmation buttons on an
 * aix-modal is 24px
 */
.aix-modal .aix-toolbar {
    .aix-btn + .aix-btn {
        margin-left: 12px;
    }
}
