@use 'libs/ui-components/src/lib/scss/shared/_variables.scss';
@use 'libs/ui-components/src/lib/scss/shared/_mixins.scss';

@use 'sass:math';

$height-info-footer: 356px;
$aside-bar-width: 340px;
$controls-width: 312px;

.aix-viewer {
    &__download-and-sign {
        position: absolute;
        background-color: variables.$color-white;
        width: 100%;
        height: calc(100% - 40px); // 40px because 56px margin top minus 16px margin bottom
        padding: 56px 0px 16px 0px;
        margin: 0;
        z-index: mixins.z('modal');
        overflow: scroll;

        &--wrapper {
            width: 990px;
            max-width: 100%;
            margin: 0 auto;
            padding: 0 24px;
        }
    }

    &__image {
        position: absolute;
        width: 100%;

        &--loading {
            display: none;
        }

        &-container {
            position: absolute;
            overflow: scroll;
            width: 100%;
            height: 100%;
        }
    }

    &__action-bar {
        position: fixed;
        width: 100%;
        height: 56px;
        background: variables.$color-quinary-black;
        box-shadow: inset 0 -1px 0 0 variables.$color-quaternary-black;
        padding: 0 10% 0 10%;

        &__container {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            justify-content: space-between;

            &--left {
                position: relative;
                float: left;
                width: calc(100% - #{$aside-bar-width});
            }

            &--right {
                display: flex;
                justify-content: flex-end;
                position: relative;
                padding-left: 16px;
                width: $aside-bar-width;
            }
        }

        &__text {
            height: 100%;
            color: variables.$color-black;
            font-size: 14px;
            line-height: 56px;
            text-align: left;
            margin: 0 16px 0 16px;
        }
    }

    &__main-wrapper {
        width: 100%;
        height: 100%;
        padding: 0 10% 0 10%;
        margin-top: 56px;
        position: relative;
        background: variables.$color-white;
    }

    &__controls {
        width: $controls-width;
        height: 40px;
        border-radius: 2px;
        background-color: rgba(35, 43, 46, 0.85);
        user-select: none;

        &-container {
            position: absolute;
            bottom: 0;
            margin-bottom: 24px;
            left: calc(50% - #{math.div($controls-width, 2)});
        }

        &-top {
            bottom: 80px !important;
            border-bottom: 1px solid variables.$color-tertiary-black;
        }

        &-inner {
            &__full {
                position: relative;
                width: 100%;
                height: 100%;
                opacity: 0.85;
                text-align: center;
            }

            &__left {
                position: relative;
                width: 50%;
                height: 100%;
                border-right: 1px solid variables.$color-tertiary-black;
                opacity: 0.85;
                display: inline-flex;
                justify-content: center;
            }

            &__right {
                position: relative;
                width: 50%;
                height: 100%;
                opacity: 0.85;
                color: variables.$color-quinary-black;
                display: inline-flex;
                text-align: center;
            }
        }

        &-label {
            position: relative;
            width: 100%;
            height: 40px;
            line-height: 40px;
            white-space: nowrap;
            color: variables.$color-quinary-black;
            opacity: 0.85;
        }
    }

    &__pager-white {
        color: variables.$color-white;
        font-weight: bold;
        opacity: 1;
    }

    &__document-wrapper {
        position: relative;
        width: 100%;
        height: calc(100% - 96px); // 96px = aix-toolbar height + collapsed navbar height
        background-color: variables.$color-tertiary-black--light;
    }

    &__document {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__left-arrow {
        display: inline-block;
        height: 20px;
        width: 20px;
        font-size: 25px;
        font-size: 1.5em;
        font-weight: 300;
        color: variables.$color-quinary-black;
        opacity: 0.85;
        line-height: 41px;
        line-height: 1.9rem;
        margin: 8px 8px 0 8px;
    }

    &__right-arrow {
        display: inline-block;
        height: 20px;
        width: 20px;
        font-size: 25px;
        font-size: 1.5em;
        font-weight: 300;
        color: variables.$color-quinary-black;
        opacity: 0.85;
        margin: 8px 8px 0 8px;
        line-height: 41px;
        line-height: 1.9rem;
    }

    &__rotate,
    &__zoom-in,
    &__zoom-out {
        display: inline-block;
        height: 20px;
        width: 20px;
        font-size: 20px;
        font-size: 2rem;
        line-height: 41px;
        line-height: 1.9rem;
        font-weight: 300;
        text-align: center;
        color: variables.$color-quinary-black;
        opacity: 0.85;
        margin: 8px 8px 0 8px;
    }

    &__fullscreen-toggle {
        display: inline-block;
        margin: 10px 8px 0 8px;
        color: variables.$color-white;
        opacity: 0.85;
        font-size: 20px;

        &.fullscreen {
            opacity: 1 !important;
        }
    }

    &__icon {
        vertical-align: middle;

        &-wrapper {
            position: relative;
            height: 100%;
            float: left;

            &__right {
                position: relative;
                height: 100%;
                float: right !important;
            }
        }
    }

    &__info-wrapper {
        position: relative;
        width: $aside-bar-width;
        height: 100%;
        background-color: variables.$color-white;
        padding: 0 16px;
        display: flex;
        flex: 1 0 auto;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        background-color: variables.$color-white;

        &-title {
            height: auto;
            width: auto;
            font-size: 16px;
            line-height: 24px;
            padding-top: 8px;
            margin-right: 16px;
            border-top: 4px solid;

            &:hover {
                cursor: pointer;
            }

            &.inactive {
                border-color: variables.$color-white;
            }
        }
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: variables.$color-white;
        overflow: auto;
        padding: 0 2px 150px 0;
        flex: 1 1 auto;
    }

    &__body-content {
        width: 100%;
        height: auto;
        padding-bottom: 300px;
        padding-right: 16px;
    }

    &__data-list {
        list-style: none;
        user-select: none;
        width: 100%;
        height: auto;

        &__item {
            font-size: 14px;
            line-height: 22px;
            padding: 16px 0;
            margin: 0;
            min-height: 54px;
            display: flex;

            &:not(:last-child) {
                border-bottom: solid 1px variables.$color-quaternary-black;
            }

            &-text {
                width: 85%;
                float: left;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            &.active {
                font-weight: bold;
            }
        }
    }
}
