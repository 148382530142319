@use "libs/ui-components/src/lib/scss/shared/mixins";
@use "libs/ui-components/src/lib/scss/shared/variables";

// /* ==========================================================================
//    Order components
//    ========================================================================== */

.aix-sign-document {
    width: 100%;
    margin-top: 16px;

    &:first-child {
        margin-top: 0;
    }

    &__name {
        margin-bottom: 8px;
        @include mixins.fz(1.5);
        color: variables.$color-primary-black;
    }

    &--generating {
        .aix-sign-document__name {
            color: variables.$color-tertiary-black;
        }
    }

    &--smaller {
        .aix-sign-document__info {
            padding-top: 7px;
        }

        .aix-sign-document__name {
            @include mixins.ellipsis;
            @include mixins.fz(1.6);
            color: variables.$color-primary-black;
            margin-bottom: 5px;
            height: 24px;
        }
    }
}

.sign-label {
    > span.sign-label:first-child {
        padding-right: 4px;
    }
    margin-bottom: 10px;
    @include mixins.fz(1.4);
    @include mixins.lh(1.7);
    color: variables.$color-primary-black;
    font-weight: 400;
    word-break: break-all;

    &--gray {
        color: variables.$color-tertiary-black;
    }
}

.investor {
    &__loading {
        display: flex;
        padding: 16px 0;
        border-bottom: variables.$color-quaternary-black solid 1px;
    }

    &__element {
        word-break: break-all;
        border-bottom: variables.$color-quaternary-black solid 1px;
    }

    &__info {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
        align-items: flex-start;
    }
}
