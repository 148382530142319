@use "variables";

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse-bordered-field {
    // 0% {
    //     box-shadow: 0 0 0 0 $aix-color-primary--light;
	// }
	// 50% {
    //     box-shadow: 0 0 1px 2px rgb(38, 166, 154, .8);
	// }
	// 100% {
    //     box-shadow: 0 0 0 0 $aix-color-primary--light;
	// }
}

@keyframes pulse-unbordered-field {
    0% {
        box-shadow: 0 0 0 0 variables.$aix-color-primary--light;
	}
	50% {
        box-shadow: 0 0 1px 3px rgb(38, 166, 154, .8);
	}
	100% {
        box-shadow: 0 0 0 0 variables.$aix-color-primary--light;
	}
}

.aix-blink {

    &-animation {
        input[type="text"].aix-form__control,
        input[type="text"].aix-dropdown__form-control
        {
            animation: pulse-bordered-field 2s ease-in-out 1;
        }

        .radio-custom-container,
        .checkboxgroup-container {
            animation: pulse-unbordered-field 3s ease-in-out 1;
        }
    }


}
