@use 'libs/ui-components/src/lib/scss/shared/_variables.scss';
@use "libs/ui-components/src/lib/scss/shared/mixins";

.order-title {
    @include mixins.fz(2.1);
}

.file-upload-container {
    padding: 24px;
    background-color: variables.$color-quinary-black;
    margin-top: -1px;
}

// TODO: Remove this class when new design is clear (context https://github.com/trade-platform/trade-platform/issues/8899#issuecomment-1934861553)
.share-dialog-container {
    // max-width: 849px;
}

.order {
    display: flex;

    &-container {
        width: 73%;
        min-height: 550px;
        position: relative;
        background: white;
        margin-top: 0;
        padding-top: 24px;

        &__no-padding {
            padding: 0;
        }
    }

    &-progress {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 36px;
        margin-bottom: 30px;

        &__value {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            color: variables.$color-tertiary-black;
        }
    }

    &-step {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 20px;

        @include mixins.dotted-line-vertical(variables.$color-tertiary-black--light, 17px);

        &:last-child {
            background-image: none;
            background-position: initial;
            background-repeat: unset;
        }

        .order-step-icon {
            padding: 0;

            &:before {
                font-family: 'Font Awesome 5 Pro';
                content: '\f00c';
            }
        }

        &__completed {
            .order-step-icon {
                padding: 0;

                &:before {
                    font-family: 'Font Awesome 5 Pro';
                    content: '\f00c';
                }
            }
        }

        &-message {
            padding: 5px 20px;
            background-color: variables.$color-quinary-black;
        }

        &-title {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
        }

        &-icon,
        &-number {
            display: inline-flex;
            justify-content: center;
            align-items: center;

            width: 24px;
            height: 36px;
            font-size: 20px;

            margin-left: 12px;
            margin-right: 10px;
        }

        &-icon__completed {
            padding: 0;
            margin-top: 0;

            &:before {
                font-family: 'Font Awesome 5 Pro';
                content: '\f00c';
            }
        }

        &-label {
            @include mixins.fz(2);

            &__signatures {
                padding-left: 0;
            }
        }

        &-content {
            padding: 24px;

            &-title {
                @include mixins.fz(2);
                @include mixins.lh(3.2);
                color: variables.$color-secondary-black;
                flex-basis: auto;
            }

            &-body {
                flex-basis: auto;
            }

            &-subtitle {
                @include mixins.fz(1.4);
                @include mixins.lh(2.2);
                color: variables.$color-secondary-black;
                padding-top: 8px;
                font-weight: normal;
            }
        }

        &-description {
            @include mixins.fz(1.4);
            @include mixins.lh(2.4);
            color: variables.$color-secondary-black;
            margin-bottom: 16px;
        }

        &-upload-description {
            margin-top: 40px;
            @include mixins.fz(1.4);
            @include mixins.lh(2.4);
            color: variables.$color-secondary-black;
        }

        &-form-progress {
            display: flex;
            align-items: center;
        }

        &-forms {
            display: flex;
            flex-direction: column;
        }

        &-form-row {
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: flex-start;
            border-bottom: 1px solid variables.$color-quaternary-black;
            padding: 16px 0;
            min-height: 4em;

            &__title {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                @include mixins.fz(1.4);
                @include mixins.lh(2.4);
            }

            &__actions {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                padding: 0;
                border-bottom: 0;

                background-image: none;
                background-position: initial;
                background-repeat: unset;

                &__border {
                    border-bottom: 1px solid variables.$color-tertiary-black--light;
                }

                &__complete {
                    justify-content: flex-end;
                }

                &__finish {
                    justify-content: space-between;
                }
            }

            &-subtitle {
                color: variables.$color-tertiary-black;
                text-transform: uppercase;
                @include mixins.fz(1.4);
                @include mixins.lh(2.2);
            }

            &-container {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: space-between;
            }
        }

        &-action-items {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            .icon {
                width: 30px;
                font-size: 2rem;
            }

            .comment-icon {
                color: variables.$color-orange;
                @include mixins.fz(2);
            }

            &__completed {
                @include mixins.fz(1.8);
            }

            &__remove {
                @include mixins.fz(1.4);
                color: variables.$color-tertiary-black;
                cursor: pointer;
            }

            &__menu-button:focus ~ .order-step-action-items-menu,
            &__menu-button:hover ~ .order-step-action-items-menu {
                display: block;
            }

            &-menu {
                list-style: none;
                background-color: variables.$color-quinary-black;
                border: 1px solid variables.$color-quaternary-black;
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
                    0 1px 3px 0 rgba(0, 0, 0, 0.2);
                position: absolute;
                padding: 8px;
                top: 24px;
                left: 0;
                z-index: mixins.z('layer-0');
                display: none;

                &:focus,
                &:hover {
                    display: block;
                }

                .aix-btn + .aix-btn {
                    margin-left: 0;
                }

                li {
                    text-align: left;
                }
            }
        }

        &-nonaction-items {
            &__title {
                @include mixins.fz(2);
                @include mixins.lh(2.4);
            }
        }
    }

    &-cards {
        display: flex;
        flex-direction: column;
        width: 25%;
        min-width: 275px;
        margin-left: 2%;
    }
}
