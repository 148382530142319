@use "libs/ui-components/src/lib/scss/shared/mixins";
@use "libs/ui-components/src/lib/scss/shared/variables";

.step {
    &-forms {
        display: flex;
        flex-direction: column;
        margin-left: 50px;
    }

    &-form-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 390px;
        height: 70px;
        border-bottom: 1px solid variables.$color-quaternary-black;

        &:last-child {
            border-bottom: 0;
        }

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }

        &-action {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__full {
            flex-direction: row;
            width: 100%;
            height: 70px;
        }

        &__title {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            border-bottom: 0;
        }

        &-item {
            display: flex;
            justify-content: space-between;
            text-align: left;

            &__selected {
                font-weight: 600;
            }
        }

        &-subtitle {
            color: variables.$color-tertiary-black;
            @include mixins.fz(1.3);
        }

        &__vertical {
            flex-direction: column;
            height: auto;
            padding-bottom: 1px;
        }

        &__horizontal {
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
        }
    }

    &-nonaction-items {
        flex-grow: 2;
    }

    &-action-items {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        min-width: 30px;

        .icon {
            width: 30px;
            font-size: 2rem;
        }

        .comment-icon {
            color: variables.$color-orange;
            @include mixins.fz(2.0);
        }

        &__completed {
            color: variables.$color-primary;
        }
    }
}

.form-icon {
    opacity: 0;

    &-selected {
        opacity: 0.3;
    }
}
