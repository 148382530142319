@use 'libs/ui-components/src/lib/scss/shared/_variables.scss';
@use 'libs/ui-components/src/lib/scss/shared/_mixins.scss';

.order-form-comments {
    @include mixins.fz(1.4);
    @include mixins.lh(2.4);

    &__input {
        width: 100%;
        resize: none;
        outline: none;
        border: 1px solid variables.$color-quaternary-black;
        padding: 8px;


        &__text-area {
            height: 136px;

            & input {
                @include mixins.fz(1.6);
                @include mixins.lh(2.0);
            }
        }
    }

    &__separator {
        border-bottom: 2px dotted variables.$color-quaternary-black;
        margin: 0;
    }

    &__header {
        height: 24px !important;
        min-height: 24px !important;
        color: variables.$color-tertiary-black;
    }

    &__comment {
        word-break: normal;
        list-style: none;
        text-align: left;
        padding: 16px 0;
        @include mixins.fz(1.4);
        @include mixins.lh(2.4);

        &-icon {
            &:hover {
                cursor: pointer;
            }
        }

        &-text {
            overflow-wrap: break-word;
            word-wrap: break-word;
            white-space: pre-wrap;

            &.resolved {
                opacity: .50;
            }
        }

        &-input {
            width: 100%;
            resize: none;
            outline: none;
            border: 1px solid variables.$color-quaternary-black;
            @include mixins.fz(1.4);
            @include mixins.lh(2.4);

            &__text-area {
                height: 80px;
            }
        }

        &.read-only {
            .order-form-comments__comment-container {
                padding: 8px;
                background-color: variables.$color-quinary-black;
            }
        }

        &:first-of-type {
            padding-top: 8px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid variables.$color-quaternary-black;
        }
    }

    &__resolve-comment {
        padding: 8px 0;
        text-align: left;
        color: variables.$color-secondary-black;
    }

    &__no-border {
        border: none !important;
    }
}

// Apply this to the comment container element
.-comment--reply {
    padding-left: 16px;
    border-left: 4px solid variables.$color-quaternary-black;
}
