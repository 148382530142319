@use "sass:color";
@use "sass:map";
@use "variables";

@charset "UTF-8";
@use 'sass:math';


// /* ==========================================================================
//      Mixins
//      ========================================================================== */

@mixin fz ($size: 2.1, $use_family: false, $font_family: '') {
    font-size: $size*10px;
    font-size: $size*1rem;
    @if $use_family==true {
        font-family: $font_family, Helvetica, Arial, sans-serif;
    }
}

@mixin lh ($size: 2.1) {
    line-height: $size*10px;
    line-height: $size*1rem;
}

@mixin hover ($only_hover: false) {
    @if $only_hover==true {
        &:hover:not([disabled]) {
            @content;
        }
    }
    @else {
        &:hover, &:focus, &:active, &.active {
            &:not([disabled]) {
                @content;
            }
        }
    }
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin center($xy:xy) {
    @if $xy==xy {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translateX(-50%) translateY(-50%);
    }
    @else if $xy==x {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }
    @else if $xy==y {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
    }
}

@mixin clearfix() {
    &:before,
    &:after {
        display: table;
        content: ' ';
    }

    &:after {
        clear: both;
    }
}


//
// Functions
// --------------------------------

// color tint and shade
@function shade( $color, $percent) {
    @return color.mix(#000, $color, $percent);
}

@function tint( $color, $percent) {
    @return color.mix(variables.$color-white, $color, $percent);
}

// A function helper to avoid having to type `map-get($z-layers, ...)`
// ---
// @param [string] $component: the layer to use
// ---
// @return [number] | [null]

@function z($layer) {
  @if not map.has-key(variables.$z-layers, $layer) {
    @warn "No z-index found in $z-layers map for `#{$layer}`. Property omitted.";
  }

  @return map.get(variables.$z-layers, $layer);
}

$dottedLineSpacing: 4;

@mixin dotted-line($color: #000, $alignment: bottom) {
    background-image: linear-gradient(to right, $color 0%, $color math.div(100%, $dottedLineSpacing), transparent math.div(100%, $dottedLineSpacing));
    background-position: 0 $alignment; background-size: ($dottedLineSpacing + px) 1px;
    background-repeat: repeat-x;
}

@mixin dotted-line-vertical($color: #000, $alignment: left) {
    background-image: linear-gradient(to top, $color 0%, $color math.div(100%, $dottedLineSpacing), transparent math.div(100%, $dottedLineSpacing));
    background-position: $alignment 0; background-size: 1px ($dottedLineSpacing + px);
    background-repeat: repeat-y;
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Common border + drop shadow combination for design
@mixin drop-shadow {
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
}

@mixin drop-shadow-hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

// Character Styles

@mixin font-weight-light {
    font-weight: 300;
}

@mixin font-weight-normal {
    font-weight: 400;
}

@mixin font-weight-medium {
    font-weight: 500;
}

@mixin h1-style {
    @include fz(3.0);
    @include lh(4.0);
    @include font-weight-medium;
}

@mixin h2-style {
    @include fz(2.4);
    @include lh(3.2);
    @include font-weight-medium;
}

@mixin h3-style {
    @include fz(2.0);
    @include lh(3.2);
    @include font-weight-medium;
}

@mixin h4-style {
    @include fz(2.0);
    @include lh(2.4);
    @include font-weight-medium;
}

@mixin h5-style {
    @include fz(1.6);
    @include lh(2.4);
    @include font-weight-medium;
}

@mixin h6-style {
    @include fz(1.4);
    @include lh(2.4);
    @include font-weight-medium;
}

@mixin body-style-normal {
    @include fz(1.4);
    @include lh(2.4);
    @include font-weight-normal;
}

@mixin body-style-small {
    @include fz(1.2);
    @include lh(1.6);
}

@mixin body-style-large {
    @include fz(1.6);
    @include lh(2.4);
}

@mixin text-style-label {
    @include body-style-small;
    color: variables.$color-tertiary-black;
    display: block;
}


// Form fields

@mixin form-field-style {
    border: none;
    outline: solid 1px variables.$color-tertiary-black--light;
    transition: outline 0.6s linear;
    background-color: variables.$color-white;
    color: variables.$color-black;

    backface-visibility: hidden;
    transition: color variables.$transition-duration, border-color variables.$transition-duration,
    background-color variables.$transition-duration, transform variables.$transition-duration;
    height: variables.$height-form-control;
    width: 100%;
    margin-bottom: variables.$margin-bottom-form-control;

    &.checkbox-custom__indicator,
    &.radio-custom__indicator {
        height: variables.$size-check-radio;
        width: variables.$size-check-radio;
        margin-bottom: 0;
    }

    input, textarea {
        width: 100%;
    }

    &:before {
        background: variables.$color-white;
    }

    @include hover {
        outline: solid 2px variables.$aix-color-primary;
        ~ .aix-label {
            color: variables.$aix-color-primary;
        }
    }

}

@mixin form-field-disabled-style {
    @include form-field-style;
    background-color: variables.$color-quaternary-black;
}

@mixin form-field-required-style {
    outline: solid 1px variables.$aix-color-primary;
    background-color: #eafaf9;

    .aix-label,
    ~ .aix-label {
        color: variables.$color-tertiary-black;
        font-weight: 500;
    }

    @include hover {
        outline: solid 2px variables.$aix-color-primary;
        ~ .aix-label {
            color: variables.$aix-color-primary;
        }
    }
}

@mixin form-field-error-style {
    @include form-field-style;
    outline: solid 1px variables.$color-red;
    &:before {
        color: variables.$color-red;
    }

    .aix-label,
    ~ .aix-label {
        color: variables.$color-red;
    }

    @include hover {
        outline: solid 2px variables.$color-red;
        ~ .aix-label {
            color: variables.$color-red;
        }
    }
}

@mixin form-field-disabled-style {
    cursor: not-allowed;
    background-color: variables.$color-quinary-black;
    color: variables.$color-tertiary-black--light;
    pointer-events: none;

    @include hover {
        border: 0 none;
        box-shadow: none;
    }
}
