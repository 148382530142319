@use "../flex-helpers";
@use "../shared/mixins";
@use "../shared/variables";

ul.filter-list {
    padding-top: 8px;

    &.-no-padding {
        padding-top: 0;
    }

    &__aside {
        width: 210px;

        &:first-child {
            padding-top: 0;
        }
    }

    li {
        @include flex-helpers.flex-between-top;
        @include mixins.fz(1.4);
        @include mixins.lh(2.4);
        margin-bottom: 8px;
        cursor: pointer;
        display: flex;

        .aix-pill {
            width: auto;
        }

        .filter-list-label {
            flex: 1;
        }
    }

    .group-divider {
        border-bottom: 1px solid variables.$color-quaternary-black;
        padding-bottom: 16px;
    }
}
