@charset "UTF-8";
/** Application Color Palette **/
/*
    Default AIX branding
    • Primary Teal: 26A69A
    • Secondary Teal: 59D9CD
    • Secondary Teal (Lightened): EAFAF9
    • Tertiary Teal: 186860
    - From Alex McGonigle as of 01-29-2019
*/
/*
    System Blacks:
    • Primary Black: 263238
    • Secondary Black: 232B2E
    • Tertiary Black: 757575
    • Tertiary Black (Lightened): A8A8A8
    • Quaternary Black: E2E2E2
    • Quinary Black: F5F5F5
    - From Alex McGonigle as of 01-29-2019
*/
/*
    Notifications:
    • Green: 81C784
    • Yellow: FFF176
    • Orange: FFB74D
    • Red: EF5350
    - From Alex McGonigle as of 01-29-2019
*/
/** Branding Color Variables **/
/*
    You can find the default AIX branding variables defined in _aix_brand.scss

    These variables below are defined as alert-level colors to help aid developers
    to apply the correct color classes to their elements.

    See _aix_brand.scss for color classes.
*/
/** Canary Signal Branding Color Palette **/
/**
    These colors will show when an element is not properly treated
    for branding through classnames. This is a developer helper and a
    signal that work needs to happen to ensure whitelabeling will
    work on an element

    The SASS does not have access to the branded colors!

        map-get($brand-colors, 'primary'); // WILL NOT WORK

    To access branding colors, you _must_ reference either a generated class
    from the brands.css file or the Javascript reference.
**/
.loading-tiny {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.host-flex {
  flex: 1 0 auto;
}

.files-selected {
  padding: 24px;
  border: 1px dashed #e2e2e2;
  margin-top: -1px;
}

.file-row {
  display: flex;
  justify-content: space-between;
  height: 24px;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 16px;
  line-height: 1.6rem;
}
.file-row__name {
  max-width: 330px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}
.file-row__name--text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-row__warning-message {
  justify-content: flex-end;
}
.file-row__actions {
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.file-row__actions--disabled {
  min-width: 140px;
  color: #757575;
}
.file-row__actions--close {
  font-size: 11px;
  font-size: 1.1rem;
  color: #757575;
  cursor: pointer;
}
.file-row__disabled {
  max-width: 200px;
  color: #757575;
}

.aix-viewer__download-and-sign {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  height: calc(100% - 40px);
  padding: 56px 0px 16px 0px;
  margin: 0;
  z-index: 5000;
  overflow: scroll;
}
.aix-viewer__download-and-sign--wrapper {
  width: 990px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 24px;
}
.aix-viewer__image {
  position: absolute;
  width: 100%;
}
.aix-viewer__image--loading {
  display: none;
}
.aix-viewer__image-container {
  position: absolute;
  overflow: scroll;
  width: 100%;
  height: 100%;
}
.aix-viewer__action-bar {
  position: fixed;
  width: 100%;
  height: 56px;
  background: #f5f5f5;
  box-shadow: inset 0 -1px 0 0 #e2e2e2;
  padding: 0 10% 0 10%;
}
.aix-viewer__action-bar__container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  justify-content: space-between;
}
.aix-viewer__action-bar__container--left {
  position: relative;
  float: left;
  width: calc(100% - 340px);
}
.aix-viewer__action-bar__container--right {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-left: 16px;
  width: 340px;
}
.aix-viewer__action-bar__text {
  height: 100%;
  color: #000000;
  font-size: 14px;
  line-height: 56px;
  text-align: left;
  margin: 0 16px 0 16px;
}
.aix-viewer__main-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 10% 0 10%;
  margin-top: 56px;
  position: relative;
  background: #ffffff;
}
.aix-viewer__controls {
  width: 312px;
  height: 40px;
  border-radius: 2px;
  background-color: rgba(35, 43, 46, 0.85);
  user-select: none;
}
.aix-viewer__controls-container {
  position: absolute;
  bottom: 0;
  margin-bottom: 24px;
  left: calc(50% - 156px);
}
.aix-viewer__controls-top {
  bottom: 80px !important;
  border-bottom: 1px solid #757575;
}
.aix-viewer__controls-inner__full {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.85;
  text-align: center;
}
.aix-viewer__controls-inner__left {
  position: relative;
  width: 50%;
  height: 100%;
  border-right: 1px solid #757575;
  opacity: 0.85;
  display: inline-flex;
  justify-content: center;
}
.aix-viewer__controls-inner__right {
  position: relative;
  width: 50%;
  height: 100%;
  opacity: 0.85;
  color: #f5f5f5;
  display: inline-flex;
  text-align: center;
}
.aix-viewer__controls-label {
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  color: #f5f5f5;
  opacity: 0.85;
}
.aix-viewer__pager-white {
  color: #ffffff;
  font-weight: bold;
  opacity: 1;
}
.aix-viewer__document-wrapper {
  position: relative;
  width: 100%;
  height: calc(100% - 96px);
  background-color: #a8a8a8;
}
.aix-viewer__document {
  position: relative;
  width: 100%;
  height: 100%;
}
.aix-viewer__left-arrow {
  display: inline-block;
  height: 20px;
  width: 20px;
  font-size: 25px;
  font-size: 1.5em;
  font-weight: 300;
  color: #f5f5f5;
  opacity: 0.85;
  line-height: 41px;
  line-height: 1.9rem;
  margin: 8px 8px 0 8px;
}
.aix-viewer__right-arrow {
  display: inline-block;
  height: 20px;
  width: 20px;
  font-size: 25px;
  font-size: 1.5em;
  font-weight: 300;
  color: #f5f5f5;
  opacity: 0.85;
  margin: 8px 8px 0 8px;
  line-height: 41px;
  line-height: 1.9rem;
}
.aix-viewer__rotate, .aix-viewer__zoom-in, .aix-viewer__zoom-out {
  display: inline-block;
  height: 20px;
  width: 20px;
  font-size: 20px;
  font-size: 2rem;
  line-height: 41px;
  line-height: 1.9rem;
  font-weight: 300;
  text-align: center;
  color: #f5f5f5;
  opacity: 0.85;
  margin: 8px 8px 0 8px;
}
.aix-viewer__fullscreen-toggle {
  display: inline-block;
  margin: 10px 8px 0 8px;
  color: #ffffff;
  opacity: 0.85;
  font-size: 20px;
}
.aix-viewer__fullscreen-toggle.fullscreen {
  opacity: 1 !important;
}
.aix-viewer__icon {
  vertical-align: middle;
}
.aix-viewer__icon-wrapper {
  position: relative;
  height: 100%;
  float: left;
}
.aix-viewer__icon-wrapper__right {
  position: relative;
  height: 100%;
  float: right !important;
}
.aix-viewer__info-wrapper {
  position: relative;
  width: 340px;
  height: 100%;
  background-color: #ffffff;
  padding: 0 16px;
  display: flex;
  flex: 1 0 auto;
}
.aix-viewer__header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  background-color: #ffffff;
}
.aix-viewer__header-title {
  height: auto;
  width: auto;
  font-size: 16px;
  line-height: 24px;
  padding-top: 8px;
  margin-right: 16px;
  border-top: 4px solid;
}
.aix-viewer__header-title:hover {
  cursor: pointer;
}
.aix-viewer__header-title.inactive {
  border-color: #ffffff;
}
.aix-viewer__content {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  overflow: auto;
  padding: 0 2px 150px 0;
  flex: 1 1 auto;
}
.aix-viewer__body-content {
  width: 100%;
  height: auto;
  padding-bottom: 300px;
  padding-right: 16px;
}
.aix-viewer__data-list {
  list-style: none;
  user-select: none;
  width: 100%;
  height: auto;
}
.aix-viewer__data-list__item {
  font-size: 14px;
  line-height: 22px;
  padding: 16px 0;
  margin: 0;
  min-height: 54px;
  display: flex;
}
.aix-viewer__data-list__item:not(:last-child) {
  border-bottom: solid 1px #e2e2e2;
}
.aix-viewer__data-list__item-text {
  width: 85%;
  float: left;
}
.aix-viewer__data-list__item-text:hover {
  cursor: pointer;
  text-decoration: underline;
}
.aix-viewer__data-list__item.active {
  font-weight: bold;
}

.aix-sign-document {
  width: 100%;
  margin-top: 16px;
}
.aix-sign-document:first-child {
  margin-top: 0;
}
.aix-sign-document__name {
  margin-bottom: 8px;
  font-size: 15px;
  font-size: 1.5rem;
  color: #263238;
}
.aix-sign-document--generating .aix-sign-document__name {
  color: #757575;
}
.aix-sign-document--smaller .aix-sign-document__info {
  padding-top: 7px;
}
.aix-sign-document--smaller .aix-sign-document__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-size: 1.6rem;
  color: #263238;
  margin-bottom: 5px;
  height: 24px;
}

.sign-label {
  margin-bottom: 10px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 17px;
  line-height: 1.7rem;
  color: #263238;
  font-weight: 400;
  word-break: break-all;
}
.sign-label > span.sign-label:first-child {
  padding-right: 4px;
}
.sign-label--gray {
  color: #757575;
}

.investor__loading {
  display: flex;
  padding: 16px 0;
  border-bottom: #e2e2e2 solid 1px;
}
.investor__element {
  word-break: break-all;
  border-bottom: #e2e2e2 solid 1px;
}
.investor__info {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: flex-start;
}

.step-forms {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}
.step-form-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 390px;
  height: 70px;
  border-bottom: 1px solid #e2e2e2;
}
.step-form-row:last-child {
  border-bottom: 0;
}
.step-form-row-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.step-form-row-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.step-form-row__full {
  flex-direction: row;
  width: 100%;
  height: 70px;
}
.step-form-row__title {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-bottom: 0;
}
.step-form-row-item {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.step-form-row-item__selected {
  font-weight: 600;
}
.step-form-row-subtitle {
  color: #757575;
  font-size: 13px;
  font-size: 1.3rem;
}
.step-form-row__vertical {
  flex-direction: column;
  height: auto;
  padding-bottom: 1px;
}
.step-form-row__horizontal {
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}
.step-nonaction-items {
  flex-grow: 2;
}
.step-action-items {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  min-width: 30px;
}
.step-action-items .icon {
  width: 30px;
  font-size: 2rem;
}
.step-action-items .comment-icon {
  color: #ffb74d;
  font-size: 20px;
  font-size: 2rem;
}
.step-action-items__completed {
  color: #26a69a;
}

.form-icon {
  opacity: 0;
}
.form-icon-selected {
  opacity: 0.3;
}

/**
 * Add here any changes to aix-data-grid
 */
.document-cell-renderer {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 400;
  color: #757575;
}

.order-form-comments {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 24px;
  line-height: 2.4rem;
}
.order-form-comments__input {
  width: 100%;
  resize: none;
  outline: none;
  border: 1px solid #e2e2e2;
  padding: 8px;
}
.order-form-comments__input__text-area {
  height: 136px;
}
.order-form-comments__input__text-area input {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 20px;
  line-height: 2rem;
}
.order-form-comments__separator {
  border-bottom: 2px dotted #e2e2e2;
  margin: 0;
}
.order-form-comments__header {
  height: 24px !important;
  min-height: 24px !important;
  color: #757575;
}
.order-form-comments__comment {
  word-break: normal;
  list-style: none;
  text-align: left;
  padding: 16px 0;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 24px;
  line-height: 2.4rem;
}
.order-form-comments__comment-icon:hover {
  cursor: pointer;
}
.order-form-comments__comment-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.order-form-comments__comment-text.resolved {
  opacity: 0.5;
}
.order-form-comments__comment-input {
  width: 100%;
  resize: none;
  outline: none;
  border: 1px solid #e2e2e2;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 24px;
  line-height: 2.4rem;
}
.order-form-comments__comment-input__text-area {
  height: 80px;
}
.order-form-comments__comment.read-only .order-form-comments__comment-container {
  padding: 8px;
  background-color: #f5f5f5;
}
.order-form-comments__comment:first-of-type {
  padding-top: 8px;
}
.order-form-comments__comment:not(:last-child) {
  border-bottom: 1px solid #e2e2e2;
}
.order-form-comments__resolve-comment {
  padding: 8px 0;
  text-align: left;
  color: #232b2e;
}
.order-form-comments__no-border {
  border: none !important;
}

.-comment--reply {
  padding-left: 16px;
  border-left: 4px solid #e2e2e2;
}

.order-title {
  font-size: 21px;
  font-size: 2.1rem;
}

.file-upload-container {
  padding: 24px;
  background-color: #f5f5f5;
  margin-top: -1px;
}

.order {
  display: flex;
}
.order-container {
  width: 73%;
  min-height: 550px;
  position: relative;
  background: white;
  margin-top: 0;
  padding-top: 24px;
}
.order-container__no-padding {
  padding: 0;
}
.order-progress {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  margin-bottom: 30px;
}
.order-progress__value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: #757575;
}
.order-step {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 20px;
  background-image: linear-gradient(to top, #a8a8a8 0%, #a8a8a8 25%, transparent 25%);
  background-position: 17px 0;
  background-size: 1px 4px;
  background-repeat: repeat-y;
}
.order-step:last-child {
  background-image: none;
  background-position: initial;
  background-repeat: unset;
}
.order-step .order-step-icon {
  padding: 0;
}
.order-step .order-step-icon:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
}
.order-step__completed .order-step-icon {
  padding: 0;
}
.order-step__completed .order-step-icon:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
}
.order-step-message {
  padding: 5px 20px;
  background-color: #f5f5f5;
}
.order-step-title {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}
.order-step-icon, .order-step-number {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 36px;
  font-size: 20px;
  margin-left: 12px;
  margin-right: 10px;
}
.order-step-icon__completed {
  padding: 0;
  margin-top: 0;
}
.order-step-icon__completed:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
}
.order-step-label {
  font-size: 20px;
  font-size: 2rem;
}
.order-step-label__signatures {
  padding-left: 0;
}
.order-step-content {
  padding: 24px;
}
.order-step-content-title {
  font-size: 20px;
  font-size: 2rem;
  line-height: 32px;
  line-height: 3.2rem;
  color: #232b2e;
  flex-basis: auto;
}
.order-step-content-body {
  flex-basis: auto;
}
.order-step-content-subtitle {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 22px;
  line-height: 2.2rem;
  color: #232b2e;
  padding-top: 8px;
  font-weight: normal;
}
.order-step-description {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 24px;
  line-height: 2.4rem;
  color: #232b2e;
  margin-bottom: 16px;
}
.order-step-upload-description {
  margin-top: 40px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 24px;
  line-height: 2.4rem;
  color: #232b2e;
}
.order-step-form-progress {
  display: flex;
  align-items: center;
}
.order-step-forms {
  display: flex;
  flex-direction: column;
}
.order-step-form-row {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #e2e2e2;
  padding: 16px 0;
  min-height: 4em;
}
.order-step-form-row__title {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 24px;
  line-height: 2.4rem;
}
.order-step-form-row__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  border-bottom: 0;
  background-image: none;
  background-position: initial;
  background-repeat: unset;
}
.order-step-form-row__actions__border {
  border-bottom: 1px solid #a8a8a8;
}
.order-step-form-row__actions__complete {
  justify-content: flex-end;
}
.order-step-form-row__actions__finish {
  justify-content: space-between;
}
.order-step-form-row-subtitle {
  color: #757575;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 22px;
  line-height: 2.2rem;
}
.order-step-form-row-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
.order-step-action-items {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.order-step-action-items .icon {
  width: 30px;
  font-size: 2rem;
}
.order-step-action-items .comment-icon {
  color: #ffb74d;
  font-size: 20px;
  font-size: 2rem;
}
.order-step-action-items__completed {
  font-size: 18px;
  font-size: 1.8rem;
}
.order-step-action-items__remove {
  font-size: 14px;
  font-size: 1.4rem;
  color: #757575;
  cursor: pointer;
}
.order-step-action-items__menu-button:focus ~ .order-step-action-items-menu, .order-step-action-items__menu-button:hover ~ .order-step-action-items-menu {
  display: block;
}
.order-step-action-items-menu {
  list-style: none;
  background-color: #f5f5f5;
  border: 1px solid #e2e2e2;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  padding: 8px;
  top: 24px;
  left: 0;
  z-index: 2;
  display: none;
}
.order-step-action-items-menu:focus, .order-step-action-items-menu:hover {
  display: block;
}
.order-step-action-items-menu .aix-btn + .aix-btn {
  margin-left: 0;
}
.order-step-action-items-menu li {
  text-align: left;
}
.order-step-nonaction-items__title {
  font-size: 20px;
  font-size: 2rem;
  line-height: 24px;
  line-height: 2.4rem;
}
.order-cards {
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 275px;
  margin-left: 2%;
}