@use "../shared/mixins";
@use "../shared/variables";

/**
 * This is component for a search box
 * Could maybe be re-used as any component that has an embedded icon on the right;
 * Re-visit when doing forms layout refactor - LMG 2020-03-24
 **/

$icon-width: 28px;

.search-box {
    border: 1px solid variables.$color-quaternary-black;
    display: flex;
    align-items: center;

    input {
        @include mixins.fz(1.4);
        @include mixins.lh(2.4);
        background: none;
        border: none;
        color: variables.$color-tertiary-black;
        font-weight: 500;
        height: 40px;
        padding: 8px 8px 8px 16px;
        width: calc(100% - #{$icon-width});

        &::placeholder {
            color: variables.$color-tertiary-black;
            font: inherit;
        }
    }

    .icon {
        @include mixins.fz(1.4);
        @include mixins.lh(2.4);
        color: variables.$color-tertiary-black;
    }
}
