@use "variables";

// rotating + flipping icons
// -------------------------

.#{variables.$fa-css-prefix}-rotate-90 {
  transform: rotate(90deg);
}

.#{variables.$fa-css-prefix}-rotate-180 {
  transform: rotate(180deg);
}

.#{variables.$fa-css-prefix}-rotate-270 {
  transform: rotate(270deg);
}

.#{variables.$fa-css-prefix}-flip-horizontal {
  transform: scale(-1, 1);
}

.#{variables.$fa-css-prefix}-flip-vertical {
  transform: scale(1, -1);
}

.#{variables.$fa-css-prefix}-flip-both,
.#{variables.$fa-css-prefix}-flip-horizontal.#{variables.$fa-css-prefix}-flip-vertical { 
  transform: scale(-1, -1);
}

.#{variables.$fa-css-prefix}-rotate-by {
  transform: rotate(var(--#{variables.$fa-css-prefix}-rotate-angle, none));
}
