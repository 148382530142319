@use 'shared/variables';
@use 'shared/mixins';

.container.footnotes {
    padding-top: 16px;
    @include mixins.fz(1.0);
    @include mixins.lh(1.4);
    color: variables.$color-secondary-black;
    margin-bottom: 40px;
    height: 100%;
    font-size: 75%;

    div {
        display: flex;
        margin-bottom: 16px;
    }

    sup.aix-footnote {
        padding: 0 4px 0 0;
    }

    a {
        cursor: pointer;
        text-decoration: none;
        transition-property: color, opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
    }

    a:hover {
        text-decoration: underline;
    }

}

sup.aix-footnote {
    cursor: pointer;
    vertical-align: super;
    font-size: 10px;
    font-weight: 800;
    padding: 0;

    &.no-click {
        cursor: default;
    }
}

.aix-footnote {
    &__id {
        position: relative;
        top: -7px;

        @include mixins.fz(.8);
    }
}

