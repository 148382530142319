@use "mixins";
@use "variables";

// base icon class definition
// -------------------------

.#{variables.$fa-css-prefix} {
  font-family: var(--#{variables.$fa-css-prefix}-style-family, '#{variables.$fa-style-family}');
  font-weight: var(--#{variables.$fa-css-prefix}-style, #{variables.$fa-style});
}

.#{variables.$fa-css-prefix},
.#{variables.$fa-css-prefix}-classic,
.#{variables.$fa-css-prefix}-sharp,
.fas,
.#{variables.$fa-css-prefix}-solid,
.far,
.#{variables.$fa-css-prefix}-regular,
.fasr,
.fal,
.#{variables.$fa-css-prefix}-light,
.fasl,
.fat,
.#{variables.$fa-css-prefix}-thin,
.fast,
.fad,
.#{variables.$fa-css-prefix}-duotone,
.fass,
.#{variables.$fa-css-prefix}-sharp-solid,
.fab,
.#{variables.$fa-css-prefix}-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--#{variables.$fa-css-prefix}-display, #{variables.$fa-display});
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fas,
.#{variables.$fa-css-prefix}-classic,
.#{variables.$fa-css-prefix}-solid,
.far,
.#{variables.$fa-css-prefix}-regular,
.fal,
.#{variables.$fa-css-prefix}-light,
.fat,
.#{variables.$fa-css-prefix}-thin {
  font-family: 'Font Awesome 6 Pro';
}

.fab,
.#{variables.$fa-css-prefix}-brands {
  font-family: 'Font Awesome 6 Brands';
}

.fad,
.#{variables.$fa-css-prefix}-classic.#{variables.$fa-css-prefix}-duotone,
.#{variables.$fa-css-prefix}-duotone {
  font-family: 'Font Awesome 6 Duotone';
}

.fass,
.fasr,
.fasl,
.fast,
.#{variables.$fa-css-prefix}-sharp {
  font-family: 'Font Awesome 6 Sharp';
}
.fass,
.#{variables.$fa-css-prefix}-sharp {
  font-weight: 900;
}

%fa-icon {
  @include mixins.fa-icon;
}
