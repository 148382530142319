@use "variables";

// bordered + pulled icons
// -------------------------

.#{variables.$fa-css-prefix}-border {
  border-color: var(--#{variables.$fa-css-prefix}-border-color, #{variables.$fa-border-color});
  border-radius: var(--#{variables.$fa-css-prefix}-border-radius, #{variables.$fa-border-radius});
  border-style: var(--#{variables.$fa-css-prefix}-border-style, #{variables.$fa-border-style});
  border-width: var(--#{variables.$fa-css-prefix}-border-width, #{variables.$fa-border-width});
  padding: var(--#{variables.$fa-css-prefix}-border-padding, #{variables.$fa-border-padding});
}

.#{variables.$fa-css-prefix}-pull-left { 
  float: left;
  margin-right: var(--#{variables.$fa-css-prefix}-pull-margin, #{variables.$fa-pull-margin}); 
}

.#{variables.$fa-css-prefix}-pull-right { 
  float: right;
  margin-left: var(--#{variables.$fa-css-prefix}-pull-margin, #{variables.$fa-pull-margin}); 
}
