@use "sass:string";
@use "variables";

// specific icon class definition
// -------------------------

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

@each $name, $icon in variables.$fa-icons {
  .#{variables.$fa-css-prefix}-#{$name}::before { content: string.unquote("\"#{ $icon }\""); }
}

