@use "mixins";
@use "variables";

// sizing icons
// -------------------------

// literal magnification scale
@for $i from 1 through 10 {
  .#{variables.$fa-css-prefix}-#{$i}x {
    font-size: $i * 1em;
  }
}

// step-based scale (with alignment)
@each $size, $value in variables.$fa-sizes {
  .#{variables.$fa-css-prefix}-#{$size} {
     @include mixins.fa-size($value);
  }
}
