.full-page {
    width: 100vw;
    height: 100vh;

    &__logo {
        width: calc((100vw - 488px)/2);
        min-width: calc((100vw - 488px)/2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
            max-height: 90px;
        }

        img {
            max-height: 220px;
            max-width: 220px;
        }

    }

    &__box {
        width: 488px;
        padding: 40px;
        background-color: #f4f3f4;
        box-shadow: #aaa 1px 1px 2px 0px;
    }

    &--flex-centered {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: flex-start;
    }
}

.fs-login {
    &__text,
    &__link {
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 23px;
        line-height: 2.3rem;
        color: #989898;
        text-align: center;
        overflow-wrap: break-word;
    }
}

.bgc-darker--2third {
    /* background-color: #333 !important; */
    background: linear-gradient(90deg, #263238 58%, #fff 38%) !important;
}

/* IE Styling */
.fs-ie {
    display: block;
    max-height: 90%;
    overflow: auto;

    & * {
        font-family: 'AktivGrotesk', sans-serif;
    }

    &__title {
        font-size: 33px;
        font-size: 3.3rem;
        line-height: 35px;
        line-height: 3.5rem;
        font-weight: 500;
        color: #232b2e;
    }

    &__body {
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0 32px 0;
        color: #232b2e;
    }

    &__icon {
        width: 100%;
        font-size: 72px;
        color: #26a69a;
        margin-bottom: 14px;
        font-family: 'Font Awesome 5 Brands';

        &-row {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
        }

        &-container {
            display: flex;
            flex-basis: 20%;
            flex-direction: column;
            justify-content: flex-start;
            text-align: center;
        }

        &-link {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:hover {
                cursor: pointer;
                text-decoration: none;
                color: #232b2e;
            }

            & .fs-ie__icon-label:hover {
                cursor: pointer;
            }
        }

        &-label {
            width: 100%;
            text-align: center;
            color: #232b2e;
            font-size: 14px;
            font-weight: bold;
            line-height: 17px;
        }
    }
}
