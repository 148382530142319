// /* ==========================================================================
//    Fonts
//    ========================================================================== */

@font-face {
    font-family: 'AktivGrotesk';
    src: url('https://assets.aixplatform.com/fonts/aktiv-grotesk/AktivGrotesk_W_Rg.eot?#iefix') format('embedded-opentype'),
    url('https://assets.aixplatform.com/fonts/aktiv-grotesk/AktivGrotesk_W_Rg.woff2') format('woff2'),
    url('https://assets.aixplatform.com/fonts/aktiv-grotesk/AktivGrotesk_W_Rg.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'AktivGrotesk';
    src: url('https://assets.aixplatform.com/fonts/aktiv-grotesk/AktivGrotesk_W_Md.eot?#iefix') format('embedded-opentype'),
    url('https://assets.aixplatform.com/fonts/aktiv-grotesk/AktivGrotesk_W_Md.woff2') format('woff2'),
    url('https://assets.aixplatform.com/fonts/aktiv-grotesk/AktivGrotesk_W_Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AktivGrotesk';
    src: url('https://assets.aixplatform.com/fonts/aktiv-grotesk/AktivGrotesk_W_Bd.eot?#iefix') format('embedded-opentype'),
    url('https://assets.aixplatform.com/fonts/aktiv-grotesk/AktivGrotesk_W_Bd.woff2') format('woff2'),
    url('https://assets.aixplatform.com/fonts/aktiv-grotesk/AktivGrotesk_W_Bd.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
