@use 'libs/ui-components/src/lib/scss/shared/_variables';
@use 'libs/ui-components/src/lib/scss/shared/_mixins';

.form-error {
    @include mixins.text-style-label;
    display: block;
    color: variables.$color-red;
    margin-top: -8px;
    margin-bottom: 8px;

    &--container {
        width: 100%;
    }
}

.aix-form__control--invalid.aix-form__control--invalid-required {
    @include mixins.form-field-required-style;

    &.aix-form__control--invalid-disabled {
        @include mixins.form-field-disabled-style;
    }


    & ~ .radio-custom__label,
    & ~ .checkbox-custom__label {
        color: variables.$color-secondary-black;
    }

    &.aix-form__control--dirty {
        @include mixins.form-field-error-style;

        & ~ .radio-custom__label,
        & ~ .checkbox-custom__label {
            color: variables.$color-red;
        }
    }
}
