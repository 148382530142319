@use '../shared/variables';
@use '../shared/mixins';

.aix-label {
    @include mixins.fz(1.2);

    &--slim {
        font-weight: 300 !important;
    }

    &--fat {
        font-weight: 700 !important;
    }

    &--dark {
        color: variables.$color-primary-black;
    }

    &--black {
        color: variables.$color-secondary-black;
    }

    &--green {
        color: variables.$color-primary;
    }

    &--grey {
        color: variables.$color-tertiary-black;
    }

    &--light-grey {
        color: variables.$color-tertiary-black;
    }

    &--xs {
        @include mixins.fz(1.1);
    }

    &--sm {
        @include mixins.fz(1.2);
    }

    &--md {
        @include mixins.fz(1.3);
    }

    &-xm {
        @include mixins.fz(1.4);
    }

    &--lg {
        @include mixins.fz(1.5);
    }

    &--lowercase {
        text-transform: none !important;
    }
}
