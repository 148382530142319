@use "variables";

// icons in a list
// -------------------------

.#{variables.$fa-css-prefix}-ul {
  list-style-type: none;
  margin-left: var(--#{variables.$fa-css-prefix}-li-margin, #{variables.$fa-li-margin});
  padding-left: 0;

  > li { position: relative; }
}

.#{variables.$fa-css-prefix}-li {
  left: calc(var(--#{variables.$fa-css-prefix}-li-width, #{variables.$fa-li-width}) * -1);
  position: absolute;
  text-align: center;
  width: var(--#{variables.$fa-css-prefix}-li-width, #{variables.$fa-li-width});
  line-height: inherit;
}
